const columns = ["活动时间","活动名称","主办校友会", "地点", 
  {
    name: "网站地址",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <a href={"http://"+value} target="_blank">{value}</a>
        );
      }
    }
  }, 
  "联系电话", "其它联系方式(如微信等等)"];

const options = {
  filterType: "checkbox",
  download: false,
  print: false,
  // resizableColumns: true
};
const data = [ [ '3月23，30，31日', '高校杯乒乓球友谊赛', '四川大学加拿大校友会', '大多伦多万锦', '', '', '' ],
[ '4月6日',
  'CIK电讯：创新科技讲座',
  '西北工业大学校友会，电子科技大学校友会',
  '大多伦多万锦',
  'cik.xiaoju.ca',
  '',
  '' ],
[ '4月7日', '高校杯桥牌赛', '南开大学校友会', '', '', '', '' ],
[ '4月20-21日', '高校室内排球赛', '哈工大加拿大校友会', '大多伦多万锦', '', '', '' ],
[ '5月11日',
  '校友会运营交流会',
  '北邮校友会，西南财大校友会，重庆大学校友会，南京大学校友会',
  '大多伦多万锦市',
  'www.cquaa.ca/shou-ci-xiao-you-hui-yun-ying-jiao-liu-hui-cheng-gong-ju-xing/',
  '',
  '' ],
[ '5月12日',
  'Plogging漫步环保',
  '苏州科技大学加拿大校友会，西南科技大学校友会',
  '多伦多',
  'sust.xiaoju.ca/20190512/',
  '',
  '' ],
  [ '5月18日', '高校羽毛球比赛', '西安交通大学多伦多校友会', '大多伦多万锦', '', '', '' ],
  [ '5月26日',
  '缘来有你 单身联谊活动',
  '南开校友会，北航校友会，东北财大校友会，清华校友会，厦大校友会，浙大校友会',
  '大多伦多列治文山',
  '',
  '陈黎 416-716-0838,施清 647-888-9918',
  '微信laogao261678' ],
[ '5月26日',
  '警民论坛系列活动',
  '兰州大学校友会，西南政法大学校友会，约克郡警局多元文化分局，青岛大学多伦多校友会(协办)',
  '大多伦多旺市',
  '',
  '',
  '' ],
  [ '6月8日', '高校摄影赛', '北京理工大学多伦多校友会', '大多伦多万锦', '', '', '' ],
  [ '7月6日', '高校草地排球赛', '西安电子科技大学校友会', '大多伦多万锦', 'www.alumnivolleyball.ca', '416-357-1869', 'canadaxidian@gmail.com' ],
[ '7月20日',
  '意外与危机管理-防身逃脱术',
  '青岛大学多伦多校友会，兰州大学校友会',
  '大多伦多旺市',
  '',
  '416-560-7928， 416-554-8238',
  '' ],
[ '8月10-11日',
  '高校校友会书画展',
  '陕西师大加拿大校友会',
  '大多伦多万锦',
  '',
  '416-262-0888',
  '' ],
[ '', '高校足球比赛', '南开大学校友会，大连理工大学校友会', '', '', '', '' ],
[ '', '忆青春高校舞会', '北京科技大学加拿大校友会', '', '', '', '' ] ];


export {columns, data, options}
